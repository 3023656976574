import React, { Fragment } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import PlayIco from '../images/play.png'

import {
  desktop,
  mobile,
  BgDesktop,
  BgMobile
} from './styles/HeroBanner.styles'

class DemoCarousel extends React.Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      videoId: null
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal (id) {
    this.setState({isOpen: true, videoId: id})
  }

  render() {
    return (
      <Fragment>
        <Carousel css={desktop} showIndicators={false} showStatus={false} showThumbs={false} boolean={false} infiniteLoop={false}>
          <div css={BgDesktop} onClick={() => this.openModal('364693989')}>
            <img src={PlayIco} alt='/' style={{ width: 164 }} />
          </div>
        </Carousel>
        <Carousel css={mobile} showIndicators={false} showStatus={false} showArrows={false} showThumbs={false} boolean={false} infiniteLoop={false}>
        <div css={BgMobile} onClick={() => this.openModal('364693989')}>
            <img src={PlayIco} alt='/' style={{ width: 164 }} />
          </div>
        </Carousel>
        <ModalVideo 
          channel='vimeo' 
          isOpen={this.state.isOpen} 
          videoId={this.state.videoId} 
          onClose={() => this.setState({isOpen: false})}
          style={{ zIndex: 99 }} 
        />
      </Fragment>
    );
  }
}

export default DemoCarousel