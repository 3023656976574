import styled from '@emotion/styled-base'
import { css } from '@emotion/core'

const navbarHeigth = '62px'

export const SmallMenu = styled('div')`
  display: none;
  text-align: center;
  @media (max-width: ${props => props.size}) {
    display: block;
  }
`
export const LargeMenu = styled('div')`
  display: block;
  text-align: center;
  @media (max-width: ${props => props.size}) {
    display: none;
  }
`
export const icon = css`
  position: relative;
  top: 5px;
  margin-right: 8px;
`
export const linkMenuNavbar = css`
  margin-left: 20px;
  font-size: 0.9rem;
  color: #DEEBFF;
  font-weight: bold;
  line-height: 17px;
  &:hover {
    color: white;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    color: #494949;
    font-size: 18px;
    img {
      width: 25px;
    }
    :hover {
      color: #4285F4;
    }
  }
`
export const logo = css`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
  height: 100%;
  border: 1px solid #fff;
`

export const dropdown = css`
  position: relative;
  margin-left: 40px;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    margin: 0;
    margin-left: -20px;
    background-color: #6F9CEB;
    width: 1px;
    height: ${navbarHeigth};
  }
`
export const flex = css`
  display: flex;
  align-items: center;
`
export const navBarMenu = css`
  display: flex;
  height: 100%;
  color: #494949;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  align-items: center;
  div:hover {
    color: #0770CF;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: none
  }
  @media (min-width: 0px) and (max-width: 960px) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }
`
export const navMenu = css`
  display: flex;
  width: 100%;
  & > div {
    height: 100%;
    width: 100%;
  }
`
export const mobileMenu = css`
  text-align: right;
  & > div:nth-child(1) {
    display: inline-flex;
    padding: 25px;
    cursor: pointer;
  }
  & > div:nth-child(2) {
    background: white;
    position: absolute;
    z-index: 999;
    top: 62px;
    left: 0;
    width: 100%;
    color: #494949;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    border-top: 1px solid #efefef;
    cursor: pointer;
    div {
      padding: 10px 0;
    }
    @media (min-width: 260px) and (max-width: 960px) {
     text-align: left;
     box-shadow: 0 0 9px 0 rgba(0,0,0,0.30);
    }
  }
`
export const navBar = css`
  display: flex;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100%;
  max-height: 100%;
  height: ${navbarHeigth};
  background-color: #4285F4;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const navBarRight = css`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
  font-weight: normal;
  p {
    color: white;
  }
  @media (min-width: 260px) and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    order: 1;
  }
`
export const addCredits = css`
  display: flex;
  align-items: center;
  @media (min-width: 260px) and (max-width: 960px) {
    display: inline-flex;
    margin-left: 20px;
    p {
      color: #494949;
      font-weight: bold;
    }
  }
`
export const mobileNavMenu = css`
  @media (min-width: 961px) {
    display: flex;
  }  
  @media (min-width: 260px) and (max-width: 960px) {  
    order: 2;
  }
`
export const mobileNavUser = css`
  @media (min-width: 961px) {
    display: none;
  }   
  @media (min-width: 260px) and (max-width: 960px) {  
    width: 100%;
    background: #f2f3f5;
    display: flex;
    flex-direction: column;
    color: #494949;
    font-weight: bold;
    a:hover {
      color: #4285F4;
    }
  }
  
`
export const mobileNavProfile = css`
  display: flex;
  align-items: center;
`
export const desktopNavDropdown = css`
  @media (min-width: 260px) and (max-width: 960px) {
    display: none;
  }
`

