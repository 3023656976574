import React, { Component } from 'react'
import { Element } from 'rc-scroll-anim'
import { Global } from '@emotion/core'
import NavBar from '../components/NavBar'
import Banner from '../components/HeroBannerSite.js'
import AOlimpiada from '../components/AOlimpiada'
import Footer from '../components/FooterSite'
import SEO from "../components/seo"

import { globalStyles } from '../components/layout.styles'

export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Veredas Quero conhecer o projeto #escolasparaofuturo" />
        <MenuContext.Provider value={this.state}>
          <Element
            id='header'
          >
            <NavBar location={this.props.location} />
          </Element>
          <Banner />
          <Element>
            <div id='AOlimpiada' style={{ display: 'block', position: 'relative', top: '-120px', visibility: 'hidden' }}></div>
            <AOlimpiada />
          </Element>
          <Footer />
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
