import React from 'react'
import ArrowDown from '../images/arrow-down.png'
import Arrow from '../images/polygon2.png'

import {
  styleAOlimpiada,
  container,
  title,
  content_2,
  contactForm,
  label,
  checkBox,
  btn,
  formInputs,
  formInputsLr,
  inputsItem,
  checksItems
} from './styles/InternalPages.styles'

const AOlimpiada = props => (
  <div css={styleAOlimpiada}>
    <div css={container}>
      <div css={title}>
        SOBRE O PROGRAMA<br />
        <img src={Arrow} alt='' />
      </div>
      <div css={content_2}>
        <h2>Influencie, inspire e engaje </h2>
        <p>Uma boa narrativa é um dos meios mais poderosos para influenciar, ensinar e inspirar. Ela cria conexões entre as pessoas, e entre as pessoas e as ideias. Constrói familiaridade e confiança e é muito mais excitante do que uma lista fria de dados ou uma discussão abstrata de ideias. Uma história convincente nos envolve e nos emociona.</p>
        <p>Uma boa história não é apenas uma ferramenta a ser usada de vez em quando. É uma estratégia essencial para o posicionamento, a comunicação e o marketing da escola. Se você deseja que a sua marca conquiste a confiança, o entusiasmo e a lealdade da comunidade escolar, e gere valor para o colégio, uma boa narrativa é a resposta. Afinal, uma boa história não é somente lembrada, ela é compartilhada!</p>
      </div>
      <div css={contactForm}>
        <h6>TENHO INTERESSE</h6>
        <img src={ArrowDown} alt='' />
        <h2>Quero conhecer o projeto <span>#escolasparaofuturo</span></h2>
        <form method="post" action="#" data-netlify="true" name="contact" style={{ margin: '0 auto', marginBottom: 90 }}>
          <input type="hidden" name="form-name" value="contact" />
          <div css={label}>
            <p>Nome:</p>
            <input type="text" name="name" id="name" required />
          </div>
          <div css={label}>
            <p>E-mail:</p>
            <input type="text" name="email" id="email" required />
          </div>
          <div css={label}>
            <p>Whatsapp:</p>
            <input type="text" name="whatsapp" id="whatsapp" required />
          </div>
          <div css={label}>
            <p>Escola:</p>
            <input type="text" name="school" id="school" required />
          </div>
          <div css={checkBox}>
            <p>Na Escola você é:</p>
            <div css={checksItems}>
              <div css={[formInputs, inputsItem]}> 
                <input id="option1" name="option1" type="checkbox" />
                <label for="option1">Professor</label>
              </div>
              <div css={[formInputs, formInputsLr]}> 
                <input id="option2" name="option2" type="checkbox" />
                <label for="option2">Coordenador</label>
              </div>
            </div>
            <div css={checksItems}>
              <div css={[formInputs, inputsItem]}> 
                <input id="option3" name="option3" type="checkbox" />
                <label for="option3">Diretor</label>
              </div>
              <div css={[formInputs, formInputsLr]}> 
                <input id="option4" name="option4" type="checkbox" />
                <label for="option4">Pai</label>
              </div>
            </div>
            <div css={checksItems}>
              <div css={[formInputs, inputsItem]}> 
                <input id="option5" name="option5" type="checkbox" />
                <label for="option5">Aluno</label>
              </div>
            </div>
          </div>
          <button type="submit" css={btn}>CADASTRE-SE</button>
        </form> 
      </div>
    </div>
  </div>
)

export default AOlimpiada
