import React, { Fragment, useState } from 'react'
import { Link } from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'
import ResponsiveMenu from './navbar/ResponsiveNavbar'
import OpenIcon from '../images/open_menu.svg'
import CloseIcon from '../images/close_menu.svg'

import {
  navBar,
  container,
  logo,
  navBarMenu,
  navMenu,
  mobileMenu,
  togle,
  btnLink,
  logoTitle,
  btnCta
} from './styles/Navbar.styles'

const NavBar = props => {
  const [visible, setVisible] = useState(false)
  return (
    <div css={navBar}>
      <div css={container}>
        <div css={logo} title='Veredas Quero conhecer o projeto #escolasparaofuturo'>
          <ScrollAnim.Link
            to='header'
            offsetTop={100}
            onFocus={() => setVisible(false)}
          >
            <Link to={props.isLogged ? '/home' : '/'} css={logoTitle}>#escolasparaofuturo</Link>
          </ScrollAnim.Link>
        </div>
        <div css={navMenu}
          style={{
            display: props.isMenuDisabled ? 'none' : 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            height: '100%'
          }}>
          <ResponsiveMenu
            menuOpenButton={<img src={OpenIcon} alt='/' />}
            menuCloseButton={<img src={CloseIcon} alt='/' />}
            changeMenuOn='960px'
            largeMenuClassName={navBarMenu}
            smallMenuClassName={mobileMenu}
            menuVisible={visible}
            setMenu={setVisible}
            menu={
              <div css={togle}>
                {props.location && !props.location.pathname.match(/^\/(#.*|\?.*)*$/)?
                  (
                    <Fragment>
                      <Link
                        to='/sobre'
                        css={btnLink}
                      >
                        SAIBA MAIS
                      </Link>
                      <Link
                        to='/faq'
                        css={btnLink}
                      >
                        PERGUNTAS FREQUENTES
                      </Link>
                      <Link
                        to='/#AOlimpiada'
                        css={btnCta}
                      >
                        TENHO INTERESSE
                      </Link>
                    </Fragment>
                  ):
                  (
                    <Fragment>
                      <Link
                        to='/sobre'
                        css={btnLink}
                      >
                        SAIBA MAIS
                      </Link>
                      <Link
                        to='/faq'
                        css={btnLink}
                      >
                        PERGUNTAS FREQUENTES
                      </Link>
                      <ScrollAnim.Link
                        to='AOlimpiada'
                        onFocus={() => setVisible(false)}
                        css={btnCta}
                      >
                        TENHO INTERESSE
                      </ScrollAnim.Link>
                    </Fragment>
                  )
                }
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default NavBar
