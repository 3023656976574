import { css } from '@emotion/core'
import Play from '../../images/play.png'
import HeroDesktop from '../../images/Herobanner.png'
import HeroMobile from '../../images/HeroMobile.png'

export const BgDesktop = css`
  background: url(${HeroDesktop}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 481px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`
export const BgMobile = css`
  background: url(${HeroMobile}) no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 400px;
  img {
    transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
  }
`

export const heroSilider = css`
  margin-top: 80px;
  margin-bottom: 32px;
  width: 100%;
  background-color: #1f3996;
  background-position: center;
  transition: .5s ease;
`
export const container = css`
  display: flex;
  margin: 0 auto;
  max-width: 1159px;
`
export const row = css`
  display: flex;
  margin-right: -1rem;
  margin-left: -1rem;
  height: 483px;
  width: 100%;
`
export const column = css`
  flex: 0 0 50%;
  max-width: 50%;
`
export const carousel = css`
  position: relative;
`
export const carouselSlide = css`
  margin-right: auto;
  margin-left: auto;
  list-style-type: none;
  text-align: center;
  display: none;
`
export const carouselSlideActive = css`
  ${carouselSlide}
  display: block;
`
export const carouselSlideTitle = css`
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  line-height: 50px;
  padding-top: 72px;
  padding-bottom: 8px;
  @media (min-width: 260px) and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin-left: 15px;
  }
`
export const carouselSlideSubTitle = css`
  color: #494949;
  font-size: 14px;
  line-height: 23px;
  padding-bottom: 24px;
`
export const carouselSlideSource = css`
  color: #494949;
  font-size: 14px;
  line-height: 23px;
`
export const carouselArrow = css`
  display: inline-block;
  cursor: pointer;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: .5;
  }
`
export const carouselArrowLeft = css`
  ${carouselArrow}
  margin-right: 10px;
`
export const carouselArrowRight = css`
  ${carouselArrow}
  margin-left: 10px;
`
export const carouselIndicators = css`
  margin: 0 12px 0 12px;
  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
`
export const carouselIndicator = css`
  display: flex;
  width: auto;
  color: #fff;
  font-size: 24px;
  letter-spacing: -0.19px;
  line-height: 33px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`
export const carouselIndicatorActive = css`
  ${carouselIndicator}
  &,
    &:hover {
      font-weight: bold;
      margin-right: 5px;
    }
`
export const contentLeft = css`
  width: 43%;
  text-align: left;
  margin-left: 15px;
`
export const contentRight = css`
  flex: 0 0 71%;
  width: 71%;
  /*background: url('../src/assets/bg_herobanner.png') no-repeat 100% 100%;*/
`

export const desktop = css`
  margin-top: 88px;
  @media (min-width: 260px) and (max-width: 1000px) {
    display: none;
  }
`
export const mobile = css`
  margin-top: 88px;
  @media (min-width: 1001px) {
    display: none;
  }
`
export const PlayButton = css`
  background: url(${Play}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: 0.5s all ease;
    :hover {
      opacity: 0.9;
      transition: opacity 0.9s all ease;
    }
`