import React from 'react'
import Vertere from '../images/vertere.png'
import Fractal from '../images/fractal.png'
import Veredas  from '../images/veredas.png'
import {
  footer,
  sectionHeading,
} from './styles/Footer.styles'

class FooterSite extends React.Component {
  state = {
    visible: false
  }

  render () {
    return (
      <div css={footer}>
        {!this.props.hiddenSponsor && <div css={sectionHeading}>
          <a href='https://www.institutovertere.com.br/' title='Instituto Vertere' target='_blank' rel='noopener noreferrer'><img src={Vertere} alt='Instituto Vertere' /></a>
          <a style={{ marginLeft: 24, marginRight: 24 }} href='https://fractaltecnologia.com.br/' title='Fractal' target='_blank' rel='noopener noreferrer'><img src={Fractal} alt='Fractal Tecnologia' /></a>
          <a href='/' title='Veredas' target='_blank' rel='noopener noreferrer'><img src={Veredas} alt='Veredas' /></a>
        </div>}
      </div>
    )
  }
}

export default FooterSite
