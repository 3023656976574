import { css } from '@emotion/core'

export const navBar = css`
  display: flex;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 88px;
  max-height: 88px;
  height: 100%;
  background-color: #2A323C;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  height: 100%;
`
export const logo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 0px) and (max-width: 1317px) {
    margin-left: 10px;
  }
`
export const navBarMenu = css`
  display: flex;
  height: 100%;
  min-height: 80px;
  color: white;
  font-size: 14px;
  letter-spacing: 0.23px;
  line-height: 19px;
  align-items: center;
  cursor: pointer;
  & > a {
    color: #545CFF;
  }
  div:not(:last-child), a:not(:last-child) {
    margin-right: 24px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: none
  }
  @media (min-width: 0px) and (max-width: 960px) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }
`
export const btn = css`
  padding: 18px 18px;
  color: #fff;
  width: 143px;
  height: 56px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.23px;
  font-weight: bold;
  line-height: 19px;
  flex-direction: inherit;
  justify-content: center;
  box-sizing: border-box;
  img {
    margin-right: 8px;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    border-radius: 0px;
    margin-left: 0px;
    width: 100%;
  }
`
export const SingUpBtn = css`
  ${btn}
  background-color: #545CFF;
  color: #fff !important;
  :hover {
    color: #fff !important;
    opacity: 0.9;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const LoginBtn = css`
  ${btn}
  background-color: #00EAC0;
  color: #545CFF !important;
  padding: 16px 18px;
  :hover {
    opacity: 0.9;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const GoToDashboard = css`
  ${btn}
  background-color: #4285F4;
  border-radius: 0 0 5px 0;
  :hover {
    background-color: #6F9CEB;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const navMenu = css`
  & > div {
    height: 100%;
    display: flex;
    align-items: center;
  }
`
export const mobileMenu = css`
  & > div:nth-child(1) {
    display: flex;
    margin: 25px;
    cursor: pointer;
  }
  & > div:nth-child(2) {
    background: #fff;
    position: absolute;
    top: 88px;
    left: 0;
    width: 100%;
    color: #171515;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    border-top: 1px solid #efefef;
    cursor: pointer;
  }
`
export const togle = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  justify-content: center;
  a {
    color: #FFFFFF !important;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    font-size: 30px;
    width: 100%;
    a {
      padding-bottom: 20px;
      padding-top: 20px;
      color: #fff !important;
    }
  }
`


export const logoTitle = css`
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  color: #FFFFFF;
  @media (min-width: 260px) and (max-width: 736px) {
    font-size: 24px;
  }
`

export const btnCta = css`
  background-color: #0071FF;
  width: 175px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  a {
    color: #FFFFFF !important;
  }
  :hover{
    opacity: 0.9;
    transition: background-color 0.3s,opacity 0.3s;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    color: #FFFFFF !important;
    font-size: 30px;
    width: 100%;
  }
`

export const btnLink = css`
  line-height: 30px;
  color: #006BB3 !important;
  :hover {
    color: #fff !important;
    text-decoration: underline;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    font-size: 30px;
    width: 100%;
    background: #2A323C;
    color: #fff !important;
  }
`