import { css } from '@emotion/core'

export const footer = css`
  margin-top: 32px;
  background: #fff;
  :focus {
    outline: 0;
  }
  a:not(:last-child) {
    margin-bottom: 24px;
  }
 
`
export const sectionHeading = css`
  text-align: center;
    padding-bottom: 34px;
    align-items: center;
    display: flex;
    justify-content: center;
  h1 {
    font-size: 48px;
    letter-spacing: 10px;
    line-height: 19px;
    color: #006BB3;
    font-weight: 300;
    line-height: 58px;
    margin-bottom: 34px;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    display: flex;
    flex-direction: column;
  }
`